<template>
  <div
    class="industryBusiness"
    v-loading.fullscreen.lock="mapLoading | productLoading | scatterLoading"
    :style="isHome ? 'background-color:white' : 'background-color:white'"
  >
    <div class="industryBusiness-search">
      <div class="tabOption">
        <div :class="tabTotal==1? 'tabOption-left tabOption-selected':'tabOption-left'" @click="changeGlobalOrChinaTotal(1)">中国</div>
        <div :class="tabTotal == 2 ? 'tabOption-right tabOption-selected' : 'tabOption-right'" @click="changeGlobalOrChinaTotal(2)">全球</div>
      </div>
      <el-autocomplete
        style="width: 60%"
        placeholder="请输入产品名称"
        v-model="searchProduct"
        class="input-with-select"
        @keydown.enter.native="getCompanyByProduct()"
        :fetch-suggestions="querySearch"
        @select="handleSelect"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getCompanyByProduct()"
        ></el-button>
      </el-autocomplete>
    </div>
    <div style="position:relative">
      <world-new
        v-if="isHome && tabTotal==2"
        :data="worldList"
        eheight="100%"
        style="width: 100%; margin-top: 2%; height: 60vh"
      ></world-new>
      <Map
        v-if="isHome && tabTotal==1"
        style="width: 100%; margin-top: 2%; height: 60vh"
        eheight="100%"
        :data="mapTotal"
      >
      </Map>
      <div v-if="isHome" class="selectType" style="left:20px;top:47vh;width: 20vh">
        <div class="img" style="width: 100px;height: 80px" @click="changeGlobalOrChinaTotal(1)" v-if="tabTotal == 2">
          <div :class="tabTotal == 1 ? 'high' : ''"></div>
          <img style="width: 100px;height: 80px" src="@/assets/images/china1.png" alt />
        </div>
        <div class="img" style="width: 100px;height: 80px" @click="changeGlobalOrChinaTotal(2)" v-else>
          <div :class="tabTotal == 2 ? 'high' : ''"></div>
          <img style="width: 100px;height: 80px" src="@/assets/images/world1.png" alt />
        </div>
      </div>
    </div>
    <div v-if="!isHome" class="industryBusiness-content">
      <div>
      <el-table
        id="companyTable"
        :data="tableData"
        :row-style="{ height: '69px' }"
        height="80vh"
        stripe
        border
        style="width: 52vw; padding-bottom: 10px"
      >
        <el-table-column prop="name" label="公司名" width="120">
        </el-table-column>
        <el-table-column prop="region" label="地区" width="120">
        </el-table-column>
        <!-- <el-table-column prop="isList" label="是否上市" width="80">
        </el-table-column> -->
        <el-table-column prop="description" label="公司介绍">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="right" width="300">
              <p>{{ scope.row.description | descTran }}</p>
              <div slot="reference" class="name-wrapper">
                <div>{{ scope.row.description | descTran }}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center">
        <el-pagination
        v-if="tabTotal==1"
            style="margin-top: 10px;"
            background
            layout="prev, pager, next"
            :current-page.sync="searchParams.page"
            :total="companyTotal"
            @current-change="handlePageChange">
          </el-pagination>
        </div>
      </div>
      <div style="position: relative">
        <div class="selectType">
          <div class="img" @click="changeGlobalOrChina(1)" v-if="tab == 2">
            <div :class="tab == 1 ? 'high' : ''"></div>
            <img src="@/assets/images/china1.png" alt />
          </div>
          <div class="img" @click="changeGlobalOrChina(2)" v-else>
            <div :class="tab == 2 ? 'high' : ''"></div>
            <img src="@/assets/images/world1.png" alt />
          </div>
        </div>
        <world-new
          v-if="tab == 2"
          :data="worldList"
          eheight="30vh"
          style="width: 25vw; margin-left: 15px"
        ></world-new>
        <Map
          v-if="tab == 1"
          style="width: 25vw; margin-left: 15px"
          eheight="30vh"
          :data="mapLatent"
        ></Map>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import WorldNew from "../components/WorldNew.vue";
import Map from "../components/Map.vue";
import areaMap from "@/json/areaMap.json";

export default {
  name: "IndustryBusiness",
  components: {
    WorldNew,
    Map,
  },
  data() {
    return {
      mapLoading: false,
      productLoading: false,
      scatterLoading: false,
      compNumWorld: 0,
      searchProduct: "",
      worldList: [],
      globalOrChina: 1,
      mapLatent: [],
      mapTotal: [],
      isSearch: false,
      isHome: true,
      tableData: [],
      tab: 1,
      tabTotal: 1,
      searchParams: {
        product: "",
        page: 1
      },
      companyTotal: 0
    };
  },
  mounted() {
    // this.listenSearchProduct();
    this.listenReset();
    this.listenScatter();
    this.listenProductLoading();
    this.listenScatterLoading();
    this.getCompanyScatt();
  },
  beforeDestroy() {
    EventBus.$off("productLoading");
    EventBus.$off("scatterLoading");
    // EventBus.$off("mapScatter");
    EventBus.$off("reset");
    // EventBus.$off("searchProduct");
  },
  methods: {
    handlePageChange(val) {
      this.searchParams.page = val
      this.getCompanyByProduct()
    },
    querySearch(queryString, cb) {
      var results = [
        {
          value: "电池",
        },
        {
          value: "连接器",
        },
        {
          value: "汽车",
        },
      ];
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(val) {
      this.searchProduct = val.value;
      this.getCompanyByProduct();
    },
    // 切换全球/中国
    changeGlobalOrChinaTotal(val) {
      this.tabTotal = this.tab = val;
      this.globalOrChina = val;
      this.searchParams.page = 1
      this.getCompanyByProduct();
    },
    // 切换全球/中国
    changeGlobalOrChina(val) {
      console.log("切换")
      this.tab = val;
      this.globalOrChina = val;
      if (!this.isHome) {
        this.getCompanyByProduct();
      }
    },
    // 监听产品公司列表加载
    listenProductLoading() {
      EventBus.$on("productLoading", (data) => {
        this.productLoading = true;
      });
    },
    // 监听散点加载
    listenScatterLoading() {
      EventBus.$on("scatterLoading", (data) => {
        this.scatterLoading = true;
      });
    },
    // 监听中国散点
    listenScatter() {
      EventBus.$on("mapScatter", (data) => {
        this.mapLatent = data;
        console.log("mapLatent", this.mapLatent);
        this.scatterLoading = false;
      });
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.isHome = true;
        this.getCompanyScatt();
      });
    },
    // 监听搜索产品
    // listenSearchProduct() {
    //   EventBus.$on("searchProduct", (data) => {
    //     // this.worldList = data.arr;
    //     console.log(data);
    //     this.tableData = data.list_company;
    //     this.isSearch = true;
    //     this.isHome = false;
    //     this.productLoading = false;
    //     document.getElementsByClassName(
    //       "el-table__body-wrapper"
    //     )[0].scrollTop = 0;
    //   });
    // },
    // 修改数据结构
    changeDataStructure(arrTemp, valueTemp) {
      let arrCon = [];
      let countComp = 0;
      for (let i = 0; i < arrTemp.length; i++) {
        arrCon.push({
          name: arrTemp[i],
          value: valueTemp[i],
        });
        countComp += valueTemp[i];
      }
      this.compNumWorld = countComp;
      return arrCon;
    },
    getCompanyByProduct() {
      if (this.searchProduct !== "") {
        // EventBus.$emit("productLoading", 1);
        this.productLoading = true;
        if (this.globalOrChina == 2) {
          api
            .getCompanyByProduct(this.searchProduct)
            .then((res) => {
              let that = this;
              that.filterData(res.list_company, 0);

              let countComp = 0;
              let arr = [];
              res.list_company.forEach((item) => {
                // 去除[xxx公司]
                item.description = item.description.replace(`[${item.name}]`, "")
                if (
                  item["region"] === "中国香港" ||
                  item["region"] === "中国台湾"
                ) {
                  arr.push({
                    name: "中国",
                  });
                } else {
                  arr.push({
                    name: item["region"],
                  });
                }
              });
              arr = arr.reduce((obj, item) => {
                let find = obj.find((i) => i.name === item.name);
                let _d = {
                  ...item,
                  value: 1,
                };
                find ? (countComp++, find.value++) : obj.push(_d);
                return obj;
              }, []);
              that.compNumCN = countComp;
              console.log("res.list_company", res.list_company);
              console.log("arr", arr);
              let product_info = {
                list_company: res.list_company,
              };
              // EventBus.$emit("searchProduct", product_info);
              this.tableData = product_info.list_company;
              this.isSearch = true;
              this.isHome = false;
              this.productLoading = false;
              document.getElementsByClassName(
                "el-table__body-wrapper"
              )[0].scrollTop = 0;
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          // EventBus.$emit("scatterLoading", 1);
          if (this.searchParams.page == 1) { // 散点是全部的统计(所以只需在第一次加载即可)
            this.scatterLoading = true;
          }
          this.searchParams.product = this.searchProduct
          // let params = {
          //   product: this.searchProduct,
          // };
          api.getCompanyByProductEs(this.searchParams).then((res) => {
            let product_info = {
              list_company: res.data,
            };
            this.companyTotal = res.count
            // EventBus.$emit("searchProduct", product_info);
            this.tableData = product_info.list_company;
            this.isSearch = true;
            this.isHome = false;
            this.productLoading = false;
            document.getElementsByClassName(
              "el-table__body-wrapper"
            )[0].scrollTop = 0;
          });
          if (this.searchParams.page == 1) { // 散点是全部的统计(所以只需在第一次加
            api.getScatterByProduct(this.searchParams).then((res) => {
              console.log("散点数据", res);
              // EventBus.$emit("mapScatter", res.data);
              this.mapLatent = res.data;
              console.log("mapLatent", this.mapLatent);
              this.scatterLoading = false;
            });
          }
        }
      }
    },
    // 过滤数据
    filterData(data, type) {
      let arrCon = [];
      let arrTemp = Object.keys(areaMap);
      let valueTemp = Object.values(areaMap);
      for (let i = 0; i < arrTemp.length; i++) {
        arrCon.push({
          nameEN: arrTemp[i],
          nameCN: valueTemp[i],
        });
      }
      // console.log("arrCon", arrCon);
      // console.log("data", this.data);
      if (type === 0) {
        data.forEach((item) => {
          for (let i = 0; i < arrCon.length; i++) {
            if (item.region === arrCon[i].nameEN) {
              item.region = arrCon[i].nameCN;
            }
          }
        });
      } else {
        data.forEach((item) => {
          for (let i = 0; i < arrCon.length; i++) {
            if (item.name === arrCon[i].nameEN) {
              item.name = arrCon[i].nameCN;
            }
          }
        });
      }

      // console.log("dataAfter", data);
      return 0;
    },
    // 获取公司散点数据
    getCompanyScatt() {
      this.mapLoading = true;
      api.getCompanyScatt().then((res) => {
        console.log("散点", res);
        let that = this;
        async function dealWithData() {
          let countComp = 0;
          let arrCon = [];
          // console.log("comp", res.data);
          let arrTemp = Object.keys(res.data);
          let valueTemp = Object.values(res.data);
          arrCon = await that.changeDataStructure(arrTemp, valueTemp);
          let a = await that.filterData(arrCon, 1);
          that.worldList = arrCon;
          that.mapLoading = false;
        }
        dealWithData();
      });
      let params = {
        product: "",
      };
      api.getScatterByProduct(params).then((res) => {
        console.log("散点数据", res);
        // EventBus.$emit("mapScatter", res.data);
        this.mapTotal = res.data;
        console.log("mapTotal", this.mapTotal);
        this.scatterLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.industryBusiness {
  width: 100%;
  margin-left: 5px;
  margin-top: 5px;
  background-color: #092765;
  padding-left: 10px;
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  // display: flex;
  min-height: 70vh;
  &-content {
    display: flex;
    margin-top: 15px;
  }
  &-search {
    width: 53vw;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  tr:first-of-type {
    background-color: #fff !important;
  }
  .el-table .cell {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: default;
  }
  .selectType {
    position: absolute;
    z-index: 10;
    top: 21vh;
    left: 10px;
    .img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }
      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }
      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  .tabOption {
    width: 140px;
    height: 27px;
    display: flex;
    margin-left: 10px;
    &-left, &-right {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #01A3D8;
      border: 2px solid #01A3D8;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      width:50%;
      height: 100%;
    }
    &-right {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &-selected {
      background-color: #01A3D8;
      color: white;
    }
  }
}
</style>
